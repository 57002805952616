//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-408:_5412,_8228,_7896,_4820,_2700,_7832,_2692,_8936;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-408')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-408', "_5412,_8228,_7896,_4820,_2700,_7832,_2692,_8936");
        }
      }catch (ex) {
        console.error(ex);
      }